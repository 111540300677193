<template>
  <div>
    <ScreenInitial />
  </div>
</template>

<script>
import ScreenInitial from '@/components/microComponents/ScreenInitial.vue';

  export default {
    components: {
    ScreenInitial,
  }
  }
</script>