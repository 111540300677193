<template>
  <main class="center">
    <h1>Área destinada a criação micro componentes</h1>
    <!-- CAMPO DE PESQUISA -->
    <section style="margin-top: 30px" class="content-input">
      <h2>Input de texto (campo de busca e cadastros)</h2>
      <div class="content-search items-center pl-2">
        <img
          class="content-iconSearch"
          src="@/assets/img/lupa.png"
          alt="Lupa"
        />
        <input class="form-inputStyle m-0 pl-3 py-1" type="text" />
      </div>
    </section>
    <!-- CHECKBOX -->
    <section style="margin-top: 30px" class="content-input">
      <h2>Checkbox</h2>
      <div class="content-checkbox">
        <input id="checkbox-1" type="checkbox" />
        <label for="checkbox-1">Cadastrar</label>
        <input id="checkbox-2" type="checkbox" />
        <label style="margin-right: 25px" for="checkbox-2">Editar</label>
        <input id="checkbox-3" type="checkbox" />
        <label style="margin-right: 26px" for="checkbox-3">Listar</label>
        <input id="checkbox-4" type="checkbox" />
        <label style="margin-right: 15.5px" for="checkbox-4">Deletar</label>
      </div>
    </section>
    <!-- INPUT DE SELECTED (CAMPO DE CADASTROS) -->
    <section class="section-nameGroup">
      <h2>Input de selected (campo de cadastros)</h2>
      <div class="content-input-nameGroup">
        <h6 class="pl-2 pt-1 mb-0">Nome do Grupo</h6>
        <input class="px-2 py-1 m-0" type="text" />
        <hr />
      </div>
    </section>
    <!-- BOTÃO DE CONFIRMAR -->
    <section style="margin-top: 30px">
      <h2>Botão cadastro</h2>
      <div>
        <b-button class="button-sucess" variant="success"> Confirmar </b-button>
      </div>
    </section>
    <!-- TABELA DE DADOS DE CADASTROS -->
    <section style="margin-top: 30px">
      <h2>Tabela (listagem de dados cadastrados)</h2>
      <div class="content-table">
        <table class="tableStyle">
          <tr>
            <th class="titleTh">Grupo</th>
          </tr>
          <tr class="rowTable">
            <td class="contentTd">Administrador</td>
            <td>
              <img
                class="icon-trashFill"
                src="@static/imgs/components/table/excluir.svg"
              />
              <img
                class="icon-pencilSquare"
                src="@static/imgs/components/table/editar.svg"
              />
            </td>
          </tr>
        </table>
      </div>
    </section>
    <!-- MODAL -->
    <section style="margin-top: 30px">
      <h2>Modal</h2>
      <div>
        <b-button id="show-btn" @click="$bvModal.show('bv-modal-example')">
          Abrir Modal
        </b-button>
        <b-modal id="bv-modal-example" hide-footer>
          <div class="d-block text-center content-modelGeneral">
            <h3 class="model-content1">
              Seu Grupo de Acesso foi realizado com
            </h3>
            <h3 class="model-content2">Sucesso!</h3>
          </div>
        </b-modal>
      </div>
    </section>
    <!-- PAGINAÇÃO -->
    <section class="section-pagination">
      <h2>Paginação</h2>
      <div class="content-pagination">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          class="mt-1"
          hide-goto-end-buttons
        >
        </b-pagination>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "ScreenInitial",
  data() {
    return {
      rows: 4,
      perPage: 1,
      currentPage: 5,
    };
  },
};
</script>

<style scoped>
.currentPageStyle {
  color: white;
  background-color: #cf0209;
}
.center{
   position: absolute;
  left: 301px;
  width: 84%;
  margin-left: 0%;
}
</style>
